.static {
	display: block;
}


.inner-banner {
	background-image: url(RESOURCE/img/banner5.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 650px;
	position: relative;
	background-position: 0 -374px;

	@media (max-width:1820px) {
		height: 450px;
		background-position: bottom;
	}

	@media (max-width:767px) {
		height: 320px;
		background-position: center;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}

	}
}

.anreise-banner.inner-banner {
	background-image: url(RESOURCE/img/img-anreise-header-1.jpg);
}

.heading1 {
	font-size: 42px;
}


.teams-bl {
	max-width: 1040px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		border: 1px solid #e8e8e8;

		.team-dt {

			border-top: none;
			padding: 20px 8px;
			text-align: center;
		}
	}
}


.contact-page {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}

	.form-control {
		border: none;
		border-bottom: 2px solid #f2f2f2;
		text-transform: uppercase;
		margin-bottom: 30px;
		border-radius: 0;
		box-shadow: inherit;
		padding: 0;
	}

	label {
		color: var(--black);
		font-weight: 700;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		padding-bottom: 10px;
	}

	.checkbox {
		label {
			text-transform: inherit;
			font-weight: normal;
			line-height: 18px;

			&:before {
				top: 1px;
			}

			&:after {
				top: 0px;
			}
		}

		a {
			color: var(--black);
			font-size: 14px;
		}

		.privacy-link {
			position: relative;
			/*top: -2px;*/
		}

	}

	.btn-go {
		box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
		font-size: 20px;
	}
}

.contact-content {
	iframe {
		@media (max-width: 991px) {
			height: 350px !important;
		}
	}
}

.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

		a {
			color: var(--primary);

			&:hover {
				color: var(--secondary);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 3px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}